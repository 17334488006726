import axios from "axios";
import { auth } from "../../firebase/firebase.js";

// Custom error handler for all APIs
const errorHandler = (error) => {
  const statusCode = error.response?.status;

  // Log errors that are not 401
  if (statusCode && statusCode !== 401) {
    console.error(error);
  }

  return Promise.reject(error.response || error);
};

// Auth handler for adding the token
const authHandler = async (config) => {
  const { authenticate = false, ...remainingParams } = config.params || {};
  if (authenticate) {
    const tokenResult = await auth.currentUser?.getIdTokenResult();
    if (tokenResult?.token) {
      config.headers["Authorization"] = `${tokenResult.token}`;
      
      // Remove the `authenticate` query parameter
      config.params = remainingParams;
    } else {
      return Promise.reject({ message: 'Authentication required but no token found' });
    }
  }
  return config;
};

// Create an Axios instance generator
const createAPI = (params) => {
  const instance = axios.create({
    ...params,
  });

  // Register interceptors
  instance.interceptors.response.use(undefined, (error) => errorHandler(error));
  instance.interceptors.request.use(
    (config) => authHandler(config),
    (error) => Promise.reject(error)
  );

  return instance;
};

// Usage: create APIs with different base URLs
export const userAPI = createAPI({ baseURL: process.env.REACT_APP_API_URL_USER });
export const globalAPI = createAPI({ baseURL: process.env.REACT_APP_API_URL_GLOBAL });
