import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/analytics";
import "firebase/functions";
import "firebase/messaging";
import "firebase/storage";
import "firebase/remote-config";
import "firebase/performance";
import { CloudFunctionName } from "../AppConstants/CloudFunctionName";
import { PLATFORM, defaultRemoteConfig } from "../AppConstants/Constants";
import { isProd } from "../utils/common.utils";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);
export const messaging = firebase.messaging.isSupported()
  ? firebase.messaging()
  : null;

export default firebase;
export const firestore = firebase.firestore();
export const database = firebase.database();
export const analytics = firebase.analytics();
export const functions = firebase.functions();
export const storage = firebase.storage();
export const remoteConfig = firebase.remoteConfig();
export const performance = firebase.performance();

remoteConfig.defaultConfig = defaultRemoteConfig; //update default config for fallback

if (!isProd) {
  remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
}

export const cloudFunction_as = firebase.app().functions("asia-south1");
// cloudFunction_as.useEmulator('localhost', 5001);
// if (!isProd) {
//   firestore.useEmulator("localhost", 8080);
// }

export const auth = firebase.auth();

const login = (email, password) => {
  return new Promise(async (res, rej) => {
    try {
      const user = await auth.signInWithEmailAndPassword(email, password);
      res(user);
    } catch (error) {
      rej(error);
    }
  });
};
const logout = async () => {
  try {
    await firebase.auth().signOut();
  } catch (error) {
    console.error(error)
  }
};

const checkUserExists = (userData) => {
  return new Promise(async (resolve, reject) => {
    try {
      let user = {
        email: userData.email,
        phonenumber: userData.phoneNumber,
      };

      const checkUserExistsRef = cloudFunction_as.httpsCallable(
        CloudFunctionName.USER_EXISTS
      );

      let res = await checkUserExistsRef(JSON.stringify(user));

      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};
const askForPermissionToReceiveNotifications = async (user) => {
  try {
    if (messaging) {
      // await messaging.requestPermission();
      const token = await messaging.getToken({
        vapidKey:
          "BHzdBxsWiKgmbmYU6q6432vVqUebuD4l_yJ90TFoiNs3dDWGvyel1SmsJmoETZes-vmZHl1-P_RoXVmZuV-n-CU",
      });
      if (token) {
        console.log("Your token is:", token);

        let dateFromLocalStorage = JSON.parse(localStorage.getItem("fcmToken"));

        let currentTimeInSeconds = Date.now() / 1000;
        let FOUR_WEEKS_IN_SECONDS = 4 * 7 * 24 * 60 * 60;

        if (dateFromLocalStorage && dateFromLocalStorage.timestamp) {
          let { timestamp } = dateFromLocalStorage;
          let DATE_AFTER_FOUR_WEEKS_IN_SECONDS =
            timestamp + FOUR_WEEKS_IN_SECONDS;

          if (DATE_AFTER_FOUR_WEEKS_IN_SECONDS >= currentTimeInSeconds) {
          } else {
            updateTokenIN_DB(user, token);
          }
          return;
        }
        updateTokenIN_DB(user, token);
      }

      return token;
    } else {
      return null;
    }
  } catch (error) {
    console.error(error);
  }
};

const updateTokenIN_DB = (user, token) => {
  let currentTimeInSeconds = Date.now() / 1000;
  let userData = {
    uid: user.uid,
    token: token,
    platform: PLATFORM.WEB_APP,
    deviceId: window?.clientId?.toString().replaceAll(".", ""),
  };
  const cloudRef = cloudFunction_as.httpsCallable(
    CloudFunctionName.USER_TOKEN_UPDATE
  );
  cloudRef(JSON.stringify(userData))
    .then((res) => {
      console.log(res);
      localStorage.setItem(
        "fcmToken",
        JSON.stringify({ token: token, timestamp: currentTimeInSeconds })
      );
    })
    .catch((error) => {
      console.log(error);
    });
};

const onMessageListener = (cb) => {
  try {
    if (messaging) {
      messaging.onMessage((payload) => {
        cb(payload);
      });
    }
  } catch (error) {
    console.error(error);
    cb(null);
  }
};


export const checkLandingPageSession = async () => {
  let deviceId = window?.clientId?.toString().replaceAll(".", "");
  try {
    let verified = true;
    if (!deviceId) {
      return verified;
    }

    let docRef = await database
      .ref(`LandingPageSessions/${deviceId}`)
      .once("value");
    if (!docRef.exists()) {
      return false;
    } else {
      let _data = docRef.val();
      return _data?.isExpired || false;
    }
  } catch (error) {
    console.error("Error :: ", error);
    return true;
  }
};
export const updateLandingPageSession = async () => {
  try {
    let verified = false;
    let deviceId = window?.clientId?.toString().replaceAll(".", "");
    if (!deviceId) {
      return verified;
    }

    let docRef = database.ref(`LandingPageSessions/${deviceId}`);
    await docRef.set({ isExpired: true });
  } catch (error) {
    console.error("Error :: ", error);
    return false;
  }
};

export const AuthManager = {
  login,
  logout,
  checkUserExists,
  askForPermissionToReceiveNotifications,
  onMessageListener,
};
