import { defaultNotAvailableText, getGlobalData } from "./EventHandler.ts";
import { UPDATE_LOYALITY_POINTS } from "./EventNames.ts";
import clevertap from "./index";

export const eventPush = (eventName, eventData) => {
  try {
    clevertap.event.push(eventName, eventData);
  } catch (error) {
    console.error(error);
  }
};
// Set lat lng for User Location
export const setUserLatLng = (lat, lng) => {
  try {
    if (!lat || !lng) {
      return;
    }
    return clevertap.getLocation(lat, lng);
  } catch (error) {
    console.error(error);
  }
};

// get clevertap id
export const getClevertapId = (cb = () => { }) => {
  return clevertap.getCleverTapID() ?? "";
};

// add login
export const onUserLogin = (user, userData) => {
  try {
    var props = {
      Site: {
        Name: `${userData?.firstName ?? ""} ${userData?.lastName ?? ""}`,
        "First Name": `${userData?.firstName ?? ""}`,
        "Last Name": `${userData?.lastName ?? ""}`,
        Identity: user.uid,
        Email: userData?.email,
        Phone: userData?.phoneNumber,
        // optional fields. controls whether the user will be sent email, push, etc.
        "MSG-email": true, // Disable email notifications
        "MSG-push": true, // Enable push notifications
        "MSG-sms": true, // Disable SMS notifications
        "MSG-whatsapp": true, // Enable WhatsApp notifications
        // custom attributes
        Speciality: userData?.speciality || defaultNotAvailableText, //Array of Strings for user properties
        Profession: userData?.profession || defaultNotAvailableText, //Array of Strings for user properties
        State: userData?.state || defaultNotAvailableText, //Array of Strings for user properties
        City: userData?.city || defaultNotAvailableText, //Array of Strings for user properties
        Country: userData?.country || defaultNotAvailableText, //Array of Strings for user properties
        "Employee Code": userData?.employeeCode || defaultNotAvailableText,
        "Loyalty Points": userData?.score || 0,
      },
    };
    setUserLatLng(userData?.latitude, userData?.longitude);

    return clevertap.onUserLogin.push(props);
  } catch (error) {
    console.error(error);
  }
};

export const onUserProfilePush = (userData) => {
  try {
    var props = {
      Site: {
        Name: `${userData?.firstName ?? ""} ${userData?.lastName ?? ""}`,
        "First Name": `${userData?.firstName ?? ""}`,
        "Last Name": `${userData?.lastName ?? ""}`,
        Identity: userData.userId,
        Email: userData?.email,
        Phone: userData?.phoneNumber,
        // optional fields. controls whether the user will be sent email, push, etc.
        "MSG-email": true, // Disable email notifications
        "MSG-push": true, // Enable push notifications
        "MSG-sms": true, // Disable SMS notifications
        "MSG-whatsapp": true, // Enable WhatsApp notifications
        // custom attributes
        Speciality: userData?.speciality || defaultNotAvailableText, //Array of Strings for user properties
        Profession: userData?.profession || defaultNotAvailableText, //Array of Strings for user properties
        State: userData?.state || defaultNotAvailableText, //Array of Strings for user properties
        City: userData?.city || defaultNotAvailableText, //Array of Strings for user properties
        Country: userData?.country || defaultNotAvailableText, //Array of Strings for user properties
        "Employee Code": userData?.employeeCode || defaultNotAvailableText,
      },
    };

    setUserLatLng(userData?.latitude, userData?.longitude);

    return clevertap.profile.push(props);
  } catch (error) {
    console.error(error);
  }
};

export const getUserAttribute = (propertyName) => {
  try {
    if (!propertyName) {
      return;
    }
    return clevertap.profile.getAttribute(propertyName);
  } catch (error) {
    console.error(error);
  }
};

export const onUserProfilePushWithSomeProperty = (userData) => {
  try {
    if (!userData) {
      return;
    }
    var props = {
      Site: {
        ...userData,
      },
    };
    return clevertap.profile.push(props);
  } catch (error) {
    console.error(error);
  }
};

export const updateLoyalityPoints = (data) => {
  try {
    let baseData = {
      ...getGlobalData({
        Page: window.location.pathname,
        URL: window.location.href,
      }),
    };
    let wholeData = { ...baseData, ...data };
    eventPush(UPDATE_LOYALITY_POINTS, wholeData);
    // push in ga also
    if (window.addGAWithUserInfo) {
      window.addGAWithUserInfo(UPDATE_LOYALITY_POINTS.replaceAll(" ","_"), wholeData)
    }
  } catch (error) {
    console.error(error);
  }
};

export const askNotificationPermission = () => {
  clevertap.notifications.push({
    // apnsWebPushId: "<apple web push id>", //only for safari browser
    // apnsWebPushServiceUrl: "<safari package service url>", //only for safari browser
    titleText: "Would you like to receive Push Notifications?",
    bodyText:
      "We promise to only send you relevant content and give you updates on your transactions",
    okButtonText: "Sign me up!",
    rejectButtonText: "No thanks",
    okButtonColor: "#eb4547",
    // askAgainTimeInSeconds: 5,
  });
  //   requestPermission();
};
//For Safari
// function requestPermission() {
//   if ("safari" in window && "pushNotification" in window.safari) {
//     var permissionData =
//       window.safari.pushNotification.permission("web.pjay.push");
//     window.safari.pushNotification.requestPermission(
//       "https://localhost:8443/Push_notification_war_exploded",
//       "web.pjay.push",
//       {},
//       function (subscription) {
//         console.log(subscription);
//         if (subscription.permission === "granted") {
//           subscriptionInfoSafari = subscription;
//           safariToken = subscription.deviceToken;
//           sending(safariToken);
//           // updateSubscriptionOnServer(subscription);
//         } else if (subscription.permission === "denied") {
//           // TODO:
//         }
//       }
//     );
//   } else {
//     alert("Push notifications not supported.");
//   }
// }
